import React, { useEffect, useState } from "react";
// components
import { DataTable, DataTableHead } from "@flixbus/honeycomb-react";
import { RefundSubTableHeaders } from "../../../../constants";
import { RefundSubItemTableRow } from "../RefundSubItemTableRow/RefundSubItemTableRow";

export const RefundSubItemsTable = ({
	interconnectionItems,
	isDisabled = false,
	maxRefundAmount = 0,
	currency = "",
	onSelectedItemsChange = () => {},
}) => {
	const [selectedItems, setSelectedItems] = useState([]);
	const [areAllSelected, setAreAllSelected] = useState(false);
	const [refundableOrderItems, setRefundableOrderItems] = useState([]);

	useEffect(() => {
		// filter order interconnectionItems to get only refundable interconnectionItems (price > 0 and <= max refund)
		if (
			interconnectionItems &&
			interconnectionItems.length &&
			maxRefundAmount
		) {
			setRefundableOrderItems([]);
			interconnectionItems.forEach((orderItem) => {
				if (
					orderItem &&
					orderItem.priceGross <= maxRefundAmount &&
					orderItem.priceGross > 0
				) {
					setRefundableOrderItems((prevState) => [...prevState, orderItem]);
				}
			});
		}
	}, [interconnectionItems, maxRefundAmount]);

	useEffect(() => {
		// if the length of selected interconnectionItems is equal to filtered interconnectionItems length, set parent item checkbox as selected
		if (
			selectedItems.length &&
			selectedItems.length === refundableOrderItems?.length
		) {
			setAreAllSelected(true);
		} else {
			setAreAllSelected(false);
		}
		// TODO: pass event to parent component with selected interconnectionItems
		// every time selected interconnectionItems change, re-calculate total refund amount
		calculateTotalRefundAmount(selectedItems);
	}, [selectedItems]);

	const handleItemSelection = (item) => {
		// set selected interconnectionItems
		setSelectedItems((prevItems) => {
			if (prevItems.includes(item)) {
				return prevItems.filter((selectedItem) => selectedItem.id !== item.id);
			}
			return [...prevItems, item];
		});
	};

	const calculateTotalRefundAmount = (interconnectionItems) => {
		if (interconnectionItems && interconnectionItems.length === 0) {
			onSelectedItemsChange(0, null);
		} else {
			// calculate total refund amount of float prices
			const sum = interconnectionItems.reduce(
				(acc, item) => acc + item.priceGross,
				0
			);
			onSelectedItemsChange(sum, interconnectionItems);
		}
	};

	return (
		// if refundableOrderItems is empty, return nothing
		!!refundableOrderItems && refundableOrderItems.length ? (
			<div data-testid="refund-sub-interconnectionItems-table">
				<DataTable
					scrollable
					headers={
						<DataTableHead data-testid="refund-sub-interconnectionItems-table-head">
							{RefundSubTableHeaders}
						</DataTableHead>
					}
				>
					{/* show refundable interconnectionItems only, with price < max refund amount */}
					{refundableOrderItems.map((orderItem, index) => (
						<>
							<RefundSubItemTableRow
								key={index}
								orderItem={orderItem}
								currency={currency}
								isDisabledItem={isDisabled}
								onItemSelection={(item) => {
									handleItemSelection(item);
								}}
								isSelectedItem={selectedItems.includes(orderItem)}
							/>
						</>
					))}
				</DataTable>
			</div>
		) : null
	);
};
