import { numberComparator } from "../utils/customComporator";
import {
	Icon,
	IconNewsletter,
	IconCall,
	IconArrowBigRight,
} from "@flixbus/honeycomb-icons-react";
import { OrderFilters, RideFilters } from "./FieldNames";
import { DataTableHead } from "@flixbus/honeycomb-react";

export const sortByNumber = {
	columnIndex: 0,
	comparator: numberComparator,
	label: "Sort orders by number",
	labelAsc: "Sort orders by number ascending",
	labelDesc: "Sort orders by number descending",
};

export const OrdersTableHeaders = (
	<DataTableHead>
		<th>Order ID</th>
		<th>Created at</th>
		<th>Status</th>
		<th>Route</th>
		<th>Departure</th>
		<th>Passenger</th>
		<th>
			<Icon InlineIcon={IconCall} size={4} />
			Phone
		</th>
		<th>
			<Icon InlineIcon={IconNewsletter} size={4} />
			Email
		</th>
		<th>Shop</th>
	</DataTableHead>
);

export const PaymentHeaders = (
	<DataTableHead>
		<th>Payment ID</th>
		<th>Status</th>
		<th>Created at</th>
		<th>PSP</th>
		<th>PSP Reference</th>
		<th>Method</th>
		<th>Amount</th>
		<th>Amount Refunded</th>
		<th>Transaction ID</th>
		<th>Payment Hash</th>
	</DataTableHead>
);

export const OrderItemsHeaders = (
	<DataTableHead>
		<th>Order Item ID</th>
		<th>Concession / Ride UID</th>
		<th>Route</th>
		<th>Passenger</th>
		<th>Phone</th>
		<th>Price</th>
		<th>Type</th>
		<th>Comments</th>
	</DataTableHead>
);

export const CommentsHeaders = [
	<b>Comment</b>,
	<b>Created</b>,
	<b>Latest Update</b>,
];

export const VouchersTableHeaders = (
	<DataTableHead>
		<th>Code</th>
		<th>Contract</th>
		<th>Status</th>
		<th>Usage Type</th>
		<th>Discount Type</th>
		<th>Revenue Type</th>
		<th>Distance Type</th>
		<th>Discount</th>
		<th>Discount Origin</th>
		<th>Credit</th>
		<th>Credit Origin</th>
		<th>Currency</th>
		<th>Application Type</th>
		<th>Used Count</th>
		<th>Created At</th>
		<th>Created By</th>
		<th>Started At</th>
		<th>Expired At</th>
		<th>Specification</th>
		<th>Comment</th>
		<th>Resend Promocode</th>
	</DataTableHead>
);

export const PromoCodesHeaders = (
	<DataTableHead>
		<th>PromoCode</th>
		<th>Prefix</th>
		<th>Usage Type</th>
		<th>Discount Type</th>
		<th>Revenue Type</th>
		<th>Status</th>
		<th>Discount</th>
		<th>Discount Origin</th>
		<th>Credit</th>
		<th>Credit Origin</th>
		<th>PromoCode Type</th>
		<th>Created At</th>
		<th>Specification</th>
	</DataTableHead>
);

export const RidesTableHeaders = (
	<DataTableHead>
		<th>ID</th>
		<th>Line</th>
		<th>
			Departure
			<Icon InlineIcon={IconArrowBigRight} size={2} />
			Arrival
		</th>
		<th>Created at</th>
		<th>Status</th>
		<th>RideViewer Link</th>
	</DataTableHead>
);

export const RefundTableHeaders = (
	<>
		<th>Order Item ID</th>
		<th>Route</th>
		<th>Passenger</th>
		<th>Price Gross</th>
		<th>Type</th>
	</>
);

export const RefundSubTableHeaders = (
	<>
		{/* Empty column header for checkboxes */}
		<th></th>
		<th>Route</th>
		<th>Date</th>
		<th>Price Gross</th>
	</>
);

export const DefaultPageSize = 10;

export const InitialRideFormValues = {
	[RideFilters.statuses]: [],
	[RideFilters.lineIds]: [],
	[RideFilters.dayOfWeeks]: [],
};

export const InitialOrderFormValues = {
	[OrderFilters.statuses]: [],
	[OrderFilters.dateSearchCondition]: null,
	[OrderFilters.lineIds]: [],
};
