import React, { useEffect, useState } from "react";
// components
import { Checkbox, DataTableRow } from "@flixbus/honeycomb-react";
import { DateAndTime, Price } from "../../../../components";

export const RefundSubItemTableRow = ({
	orderItem,
	currency = "",
	key,
	onItemSelection = () => {},
	isSelectedItem,
	isDisabledItem = false,
}) => {
	const [isSelectedRow, setIsSelectedRow] = useState(false);

	useEffect(() => {
		if (orderItem && isSelectedItem) {
			setIsSelectedRow(true);
		} else {
			setIsSelectedRow(false);
		}
	}, [isSelectedItem]);

	const handleRowSelection = () => {
		// TODO: pass event to parent component with selected interconnectionItem
		// set selected row in Refund Table
		setIsSelectedRow((prevChecked) => !prevChecked);
		// pass event to parent
		onItemSelection(orderItem);
		return;
	};

	return orderItem ? (
		<>
			<DataTableRow
				key={key}
				data-testid="refund-sub-item-row"
				checkbox={
					<Checkbox
						small
						aria-label={"Select Sub Item"}
						checked={isDisabledItem ? false : isSelectedRow}
						onChange={handleRowSelection}
						id={orderItem.id}
						value={orderItem.id}
						disabled={isDisabledItem}
					/>
				}
			>
				{/* route name */}
				<td>
					<div className="">
						<span>{orderItem.fullRouteName}</span>
					</div>
				</td>
				{/* date and time info */}
				<td>
					<div className=" ">
						<DateAndTime
							timeStamp={orderItem.departure}
							isDepartureType={false}
						/>
					</div>
				</td>
				{/* price info */}
				<td>
					<div className="orderPrice">
						<Price value={orderItem.priceGross} currency={currency} />
					</div>
				</td>
			</DataTableRow>
		</>
	) : null;
};
